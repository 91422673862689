import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { isMobile, getSelectorsByUserAgent } from 'react-device-detect';

import Layout from '../../layout';
import { render } from '../../components/render';
import ErrorPage from '../../components/ErrorPage';
import { insertBreadcrumb } from '../../utils/common';

import { getPage } from '../../redux/page/page.action';
import { pageGetData } from '../../redux/page/page.selector';
import { getGlobal } from '../../redux/global/global.action';
import { globalGetData, globalInit } from '../../redux/global/global.selector';

// import { reformatGlobal } from '../../utils/formatData';

const IndexPage = props => {
    const { Global, Seo, Contents, breadcrumb, isErrorPage, isMobileUA } = props;
    const firstCompType = Contents?.length ? Contents[0].type : isErrorPage ? 'error' : '';
    insertBreadcrumb(Contents, breadcrumb);
    return (
        <Layout {...Global} firstCompType={firstCompType}>
            <NextSeo title={Seo?.pageTitle} description={Seo?.pageDescription} canonical={Seo?.pageUrl} />
            <Head>
                <link rel='shortcut icon' href={Global.favicon?.url} />
                <meta name='title' content={Seo?.pageTitle} />
                <meta name='keywords' content={Seo?.pageKeywords} />
                <meta property='og:url' content={Seo?.pageUrl} />
                <meta property='og:title' content={Seo?.pageOgTitle} />
                <meta property='og:description' content={Seo?.pageOgDescription} />
                <meta property='og:image' content={Seo?.pageOgImage} />
            </Head>
            {Contents &&
                Contents.map((content, i) => render(content, i, Contents, false, isMobileUA, Global.googleMapKey))}
            {isErrorPage && <ErrorPage />}
        </Layout>
    );
};

IndexPage.getInitialProps = async ctx => {
    const { query, res, req } = ctx;
    const { lang, preview, pageId } = query;
    const { dispatch, getState } = ctx.store;
    const { pagepath } = query;
    let composePath = '';
    if (pagepath && pagepath.length > 0) {
        composePath = pagepath.join('/');
    }

    let isMobileUA = false;
    if (req) {
        isMobileUA = getSelectorsByUserAgent(req.headers['user-agent']).isMobile;
    } else {
        isMobileUA = isMobile;
    }

    let isErrorPage = false;
    await dispatch(getPage(composePath, lang, preview, pageId));
    const PageData = pageGetData(getState());
    if (res && !PageData.SEO) {
        res.statusCode = 404;
        isErrorPage = true;
    }

    // global header, footer
    const isGlobalInit = globalInit(getState());

    if (!isGlobalInit) {
        await dispatch(getGlobal(lang));
    }

    const GlobalData = globalGetData(getState());
    const Global = GlobalData.data;

    // const Global = reformatGlobal(PageData.Global, Globals, lang);
    const Seo = PageData.SEO;
    const Contents = PageData.contents;
    const breadcrumb = PageData.breadcrumb;

    return {
        Global,
        Seo,
        Contents,
        breadcrumb,
        isErrorPage,
        isMobileUA,
    };
};

IndexPage.propTypes = {
    Global: PropTypes.object,
    Seo: PropTypes.object,
    Contents: PropTypes.array,
    breadcrumb: PropTypes.object,
    isErrorPage: PropTypes.bool,
    isMobileUA: PropTypes.bool,
};

export default IndexPage;
